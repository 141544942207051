<template>
    <div>
      <div v-if="Object.keys(deliveries).length > 0">
        <b-card-group class="mb-4" deck v-for="(delivery, hash, index) in deliveries" v-bind:key="hash">
          <b-card no-body header-tag="header">
            <a slot="header" v-b-toggle="'tab-' + hash">
              <span class="when-opened float-right text-primary">
                 <i class="fa-solid fa-chevron-down" aria-hidden="true"/>
              </span>
               <span class="when-closed float-right text-primary">
                 <i class="fa-solid fa-chevron-right" aria-hidden="true"/>
              </span>
              <h5 class="mb-0 mt-0 py-0 text-left text-primary">
                {{ $t('delivery') }}
                <small>{{ datetime(delivery[0].date_created) }}</small>
              </h5>
            </a>
            <b-collapse :visible="index === 0" :id="'tab-' + hash">
              <b-card-body>
                <h6 class="text-left">{{ $t('attachments') }}</h6>
                <div class="row">
                  <div class="col-lg-4 col-md-6" v-for="(item, index) in delivery" v-bind:key="index" >
                    <b-card-group deck>
                      <DownloadItem :item="item" type="delivery" />
                    </b-card-group>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-card-group>
      </div>
      <div v-else class="mx-auto no-delivery">
        <h4>{{ $t('delivery_empty_heading') }}</h4>
        <i class="mb-3 fa-solid fa-gift fa-3x" aria-hidden="true"></i>
      </div>
    </div>
</template>

<script>
import DownloadItem from '@/components/MyAccount/DownloadItem'

export default {
  name: 'delivery',
  components: { DownloadItem },
  props: {
    deliveries: [Object, Array]
  },
  data () {
    return {
      downloading: false
    }
  },
  methods: {
    datetime (date) {
      if (date === null) {
        return ''
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>

<style scoped>
    img {
        height: 100px;
        object-fit: cover;
    }
    .description {
        background-color: #EEEEEE;
        border-radius: 1.5%;
    }

    .no-delivery {
      color: lightslategray;
    }

    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
      display: none;
    }
</style>
