<template>
  <div>

    <div class="d-flex justify-content-between pb-2">
      <h4 class="text-left">
        <template v-if="isCustom || isJob">{{ $t('quote_requirements') }}</template>
        <template v-else>{{ $t('requirements') }}</template>
      </h4>
      <GoogleTranslate ref="googleTranslate" v-on:translate="translateWording"/>
    </div>

    <p v-if="translatedError !== null" class="alert alert-danger">{{ translatedError }}</p>
    <p class="text-left" v-if="isCustom">{{ translatedCustomQuote === null ? customQuoteRequirements : translatedCustomQuote }}</p>
    <template v-if="isJob">
      <template v-if="requirements.length > 0">
        <div class="border p-3 mb-3">
          <div class="text-left" v-html="this.$sanitize(requirements[0].message, { allowedTags: ['br', 'strong', 'p', 'ul', 'li'] })">{{ description }}</div>
        </div>
        <div class="row" v-if="requirements[0].attachments">
          <div class="col-lg-4 col-md-6" v-for="(attachment, index) in requirements[0].attachments" v-bind:key="index">
            <b-card-group deck class="mb-3">
              <DownloadItem :item="attachment" type="requirement" :options="{ orderHash: hash }"/>
            </b-card-group>
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="requirements.length === 0">
      <div v-if="order.buyer_username === username">
        <h4 class="text-left" v-if="isCustom">{{ $t('additional_info') }}</h4>
        <Requirements :receive="true" :hash="hash" :description="(isCustom) ? false : description" class="mx-auto no-requirements"/>
      </div>
      <div v-else-if="!isCustom" class="text-left">
        <p>{{ $t('requirements_empty_heading') }}</p>
      </div>
    </template>
    <template v-else>
      <h4 class="text-left" v-if="isCustom">{{ $t('additional_info') }}</h4>
      <div v-if="requirements.length > 0" class="text-left" style="white-space: pre-wrap">
        <p v-if="translatedRequirement == null">{{ requirements[0].message }}</p>
        <p v-else>{{ translatedRequirement }}</p>
      </div>

      <div class="row" v-if="requirements[0].attachments">
        <div class="col-lg-4 col-md-6" v-for="(attachment, index) in requirements[0].attachments" v-bind:key="index">
          <b-card-group deck class="mb-3">
            <DownloadItem :item="attachment" type="requirement" :options="{ orderHash: hash }"/>
          </b-card-group>
        </div>
      </div>
    </template>

    <p class="mb-0" v-if="requirements.length > 0 || (isCustom || isJob)"><small>{{ $t('order_requirements_agreement') }}</small></p>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import Requirements from '../../views/Checkout/CheckoutRequirements'
import GoogleTranslate from '../Global/GoogleTranslate'
import DownloadItem from '@/components/MyAccount/DownloadItem'

export default {
  name: 'Requirement',
  components: { DownloadItem, GoogleTranslate, Requirements },
  props: {
    requirements: Array,
    hash: String,
    order: Object,
    username: String,
    description: String,
    customQuoteRequirements: {
      type: [String, Boolean],
      required: false,
      default () {
        return null
      }
    },
    orderView: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      translatedRequirement: null,
      translatedError: null,
      translatedCustomQuote: null,
      downloading: false
    }
  },
  methods: {
    translateWording (event) {
      this.translatedError = null
      this.translatedRequirement = null

      let locale = event.target.value
      if (locale === '') {
        return
      }

      this.$refs.googleTranslate.showSpinner(true)

      let data = {}

      if (this.requirements.length > 0) {
        data.requirement = this.requirements[0].message
      }

      if (this.customQuoteRequirements !== false) {
        data.custom_quote = this.customQuoteRequirements
      }

      ApiService.getTranslation(data, locale).then(response => {
        if (this.customQuoteRequirements !== false) {
          this.translatedCustomQuote = response.data.data.custom_quote
        }

        if (this.requirements.length > 0) {
          this.translatedRequirement = response.data.data.requirement
        }

        this.$refs.googleTranslate.showSpinner(false)
      }).catch((error) => {
        this.$refs.googleTranslate.showSpinner(false)
        this.translatedRequirement = null
        this.translatedError = error.response.data.error_message
      })
    }
  },
  computed: {
    isCustom () {
      return this.customQuoteRequirements !== false
    },
    isJob () {
      return this.order.items[0].gig_slug.indexOf('-job-') > -1
    }
  }
}
</script>

<style scoped>
.no-requirements {
  color: lightslategray;
}

#googleTranslate {
  width: 25%;
}
</style>
