<template>
    <div class="text-left">
      <light-timeline :items="timelines">
          <template slot="tag" slot-scope="{ item }">
            {{ date(item.date_created) }}
          </template>
          <template slot="content" class="pt-1" slot-scope="{ item }">
            {{ item.title }}
          </template>
        </light-timeline>
    </div>
</template>

<script>
export default {
  name: 'Timeline',
  props: {
    timelines: Array
  },
  methods: {
    date (date) {
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    }
  }
}
</script>

<style scoped>

  .item-symbol {
    padding-top: 10px;
  }

</style>
