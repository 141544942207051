<template>
  <b-card no-body v-on:click="requestDownload(item.hash)">
    <b-card-img :src="getImage" :class="{'image-cover' : item.thumbnail !== null}"/>
    <b-card-body class="text-muted text-break text-truncate">
      <small v-b-popover.hover.top="item.filename">{{ item.filename }}</small>
    </b-card-body>

    <b-button class="w-100" variant="primary">
      <div v-if="downloading">
        <b-spinner small />
      </div>
      <div v-else class="d-flex justify-content-between">
        <div>{{ $t('download') }}</div>
        <div><i class="fa-solid fa-download shadow-sm" aria-hidden="true" /></div>
      </div>
    </b-button>

    <b-card-footer v-if="Object.keys(item).indexOf('order_asset_stats') > -1 && item.order_asset_stats.length > 0">
      <small>The Buyer has downloaded this {{ item.order_asset_stats.length }}x</small>
    </b-card-footer>
  </b-card>
</template>

<script>
import ApiService from '../../apiService.js'

export default {
  name: 'downloadItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      downloading: false
    }
  },
  methods: {
    requestDownload (hash) {
      this.downloading = true
      if (this.type === 'delivery') {
        ApiService.getDeliveryAttachment(hash).then(response => {
          this.forceDownload(response.data.data)
          this.downloading = false
        }).catch(() => {
          this.downloading = false
        })
      } else if (this.type === 'requirement') {
        ApiService.getRequirementAttachment(this.options.orderHash, hash).then(response => {
          this.forceDownload(response.data.data)
          this.downloading = false
        }).catch(() => {
          this.downloading = false
        })
      }
    },
    forceDownload (href) {
      let anchor = document.createElement('a')
      anchor.href = href

      if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
        document.location = anchor.href
      } else {
        anchor.target = '_blank'
        anchor.download = this.$props.item.filename
        document.body.appendChild(anchor)
        anchor.click()
        anchor.remove()
      }
    },
    getName (filename) {
      return filename.replace('.' + this.getFileExtension(filename), '')
    },
    getFileExtension (filename) {
      return filename.split('.').pop()
    }
  },
  computed: {
    getImage () {
      if (this.item.thumbnail === null) {
        return require('../../assets/Icons/icon-file.png')
      }
      return this.item.thumbnail
    }
  }
}
</script>

<style scoped lang="scss">
img {
  max-height: 150px;
  object-fit: scale-down;
  &.image-cover {
     object-fit: cover;
   }
}
button {
  border-radius: 0 0 3px 3px
}
</style>
